.MuiInput-underline:after {
    border-bottom: 2px solid #DB1F26 !important;
}

.MuiButton-containedPrimary {
    color: #fff;
    background-color: #DB1F26 !important;
}

input:focus{
    background-color: rgba(219, 31, 38, .2) !important;
    background-image: none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 32px white inset;/*your box-shadow*/
    -webkit-text-fill-color: #333;
}

.MuiFormHelperText-root.Mui-error {
    color: transparent !important;
}