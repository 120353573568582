@import url(http://fonts.googleapis.com/css?family=Lato&subset=latin,latin-ext);
@import url(https://fonts.googleapis.com/css2?family=Work+Sans);
@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiSnackbar-root {
    background-color: #DB1F26;
    margin-top: 128px !important;
}

@media only screen and (max-width: 959px) {
    .mobile-warning {
        background: linear-gradient(91.81deg, #E0E0E0 1.29%, #F5F3F3 128.06%), #F3F2F2;
        opacity: 0.8;
        height: 100vh;
        width: 100vw;
        z-index: 1000;
    }

    .hide-on-mobile {
        display: none !important;
    }

}

@media only screen and (min-width: 960px) {
    .mobile-warning {
        z-index: -10000;
        display: none;
    }

    .hide-on-mobile {
        display: unset !important;
    }
}

.mobile-text {
    animation: color-change 5s infinite;
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: bolder;
    font-size: 40px;
    line-height: 48px;
    letter-spacing: 0.02em;
    text-align: center;
    margin: 25% 10%;
}

@keyframes color-change {
    0%, 100% {
        color: #000000;
    }
    50% {
        color: #DB1F26;
    }
}

.MuiGrid-root .MuiGrid-item .orderSummary {
    margin-left: 10% !important;
}

.loading-icon {
    width: 50px;
    height: 50px;
    color: #fff;
    position: absolute;
    left: 44%;
    top: 34%
}

.MuiFormLabel-root.Mui-focused {
    color: #DB1F26 !important;
}

.makeStyles-errors-27 {
    margin-top: 16px;
    border-left: 8px solid #DB1F26 !important;
    color: #000000 !important;
}

.MuiSwitch-colorPrimary.Mui-checked {
    color: #DB1F26 !important;
}
.MuiSwitch-track {
    background-color: #DB1F26 !important;
}
.backgroundGrey {
    background: linear-gradient(91.81deg, #E0E0E0 1.29%, #F5F3F3 128.06%), #F3F2F2;
    opacity: 0.8;
    width: 100vw !important;
    height: calc(100vh - 128px);
    height: -webkit-calc(100vh - 128px);
}

.regionSelect {
    height: 64px;
    width: 352px;
    background-color: white;
    margin: 20px 10px 20px 10px;
}

.lowerButton2 {
    font-family: 'Lato', sans-serif !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 16px !important;
    line-height: 40px !important;
    text-align: center !important;
    letter-spacing: 0.02em !important;
    text-transform: uppercase !important;
    background-color: #DB1F26 !important;
    border: 1px solid #DB1F26 !important;
    color: #FFFFFF !important;
    width: 352px !important;
    height: 64px !important;
    /*margin: 10% 5% 10% 5%;*/
    border-radius: 0 !important;
}

.h3Style {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: bolder;
    font-size: 40px;
    line-height: 48px;
    letter-spacing: 0.02em;
    color: #000000;
    text-align: left;
    margin: 0 0 0 0;
}

.h5Style {
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    opacity: 0.7;
    margin: 10% 0 0 0;
}

.sfMapDot {
    position: absolute;
    /*height: 10px;*/
    /*width: 10px;*/
    background-color: #DB1F26;
    border-radius: 50%;
    left: 13.5%;
    top: 44.5%;
}

.lonMapDot {
    position: absolute;
    /*height: 10px;*/
    /*width: 10px;*/
    background-color: #DB1F26;
    border-radius: 50%;
    left: 46.5%;
    top: 36%;
}

.nycMapDot {
    position: absolute;
    /*height: 10px;*/
    /*width: 10px;*/
    background-color: #DB1F26;
    border-radius: 50%;
    left: 26%;
    top: 42%;
}

.sgpMapDot {
    position: absolute;
    /*height: 10px;*/
    /*width: 10px;*/
    background-color: #DB1F26;
    border-radius: 50%;
    left: 74.5%;
    top: 60%;
}

.amsMapDot {
    position: absolute;
    /*height: 10px;*/
    /*width: 10px;*/
    background-color: #DB1F26;
    border-radius: 50%;
    left: 48%;
    top: 36%
}

.fraMapDot {
    position: absolute;
    /*height: 10px;*/
    /*width: 10px;*/
    background-color: #DB1F26;
    border-radius: 50%;
    left: 49.5%;
    top: 37%;
}

.torMapDot {
    position: absolute;
    /*height: 10px;*/
    /*width: 10px;*/
    background-color: #DB1F26;
    border-radius: 50%;
    left: 25%;
    top: 39%;
}

.blrMapDot {
    position: absolute;
    /*height: 10px;*/
    /*width: 10px;*/
    background-color: #DB1F26;
    border-radius: 50%;
    left: 68%;
    top: 55%;
}

.pricingP {
    height: 75px !important;
    width: 125px !important;
    font-family: 'Lato', sans-serif !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 28px !important;
    letter-spacing: 0.01em !important;
    text-transform: capitalize !important;
    color: #000000 !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    /*border-bottom: 1px solid rgba(196, 196, 196, .2);*/
}

.pricingPAC {
    height: 75px !important;
    width: 125px !important;
    font-family: 'Lato', sans-serif !important;
    font-style: normal !important;
    font-weight: 1000 !important;
    font-size: 16px !important;
    line-height: 28px !important;
    letter-spacing: 0.01em !important;
    text-transform: capitalize !important;
    color: #000000 !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    /*border-bottom: 1px solid rgba(196, 196, 196, .2);*/
}

.pricingBox {
    width: 125px !important;
    background: #FFFFFF !important;
    box-shadow: 0px 5.41176px 37.8824px rgba(0, 0, 0, 0.1) !important;
    border-radius: 10.8235px !important;
    /*// paddingTop: 10px !important;*/
    margin: 0 15px 0 15px !important;
    text-align: center !important;
}

.pricingTableCol {
    width: 125px !important;
    /*padding-top: 10px !important;*/
    margin: 0 15px 0 15px !important;
    text-align: center !important;
}

.gray-button {
    margin-top: 10px !important;
    margin-left: 15px !important;
    width: 125px !important;
    background-color: rgba(196, 196, 196, .8) !important;
    border: rgba(196, 196, 196, .8) !important;
    font-family: 'Lato', sans-serif !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 12px !important;
    line-height: 22px !important;
    text-align: center !important;
    letter-spacing: 0.05em !important;
    text-transform: uppercase !important;
    color: #FFFFFF !important;
}

.gray-button:hover {
    background-color: rgba(196, 196, 196, .5) !important;
    border: rgba(196, 196, 196, .5) !important;
}

.red-button {
    margin-top: 10px !important;
    margin-left: 15px !important;
    width: 125px !important;
    background-color: #DB1F26 !important;
    border: #DB1F26 !important;
    font-family: 'Lato', sans-serif !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 12px !important;
    line-height: 22px !important;
    text-align: center !important;
    letter-spacing: 0.05em !important;
    text-transform: uppercase !important;
    color: #FFFFFF !important;
}

.red-button:hover {
    background-color: rgba(219, 31, 38, .8) !important;
    border: rgba(219, 31, 38, .8) !important;
}

.buttonStyleInternal {
    width: 100% !important;
    height: 75px !important;
    background-color: #DB1F26 !important;
    border: #DB1F26 !important;
    font-family: 'Lato', sans-serif !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 12px !important;
    line-height: 28px !important;
    text-align: center !important;
    letter-spacing: 0.05em !important;
    text-transform: uppercase !important;
    color: #FFFFFF !important;
}

.buttonStyleInternal:hover {
    background-color: rgba(219, 31, 38, .8) !important;
    border: rgba(219, 31, 38, .8) !important;

}

.hidden-spacer-buttons {
    /*margin-top: -10px !important;*/
    margin-left: 5% !important;
    width: 125px !important;
    height: 75px !important;
    background-color: transparent !important;
    border: transparent !important;
    color: transparent !important;
    font-family: 'Lato', sans-serif !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 16px !important;
    line-height: 40px !important;
    text-align: center !important;
    letter-spacing: 0.05em !important;
    text-transform: uppercase !important;
}
.hidden-spacer-buttons:hover {
    background-color: rgba(219, 31, 38, .8) !important;
    border: rgba(219, 31, 38, .8) !important;
}

.slider-text {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */
    letter-spacing: 0.02em;
    text-transform: capitalize;
    text-align: left;
    color: #000000;
    margin: 15px 0 0px 10px !important;
}

.recommended {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    /* identical to box height, or 100% */
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #000000;
    padding: 5% 5% 1% 5% !important;
}

.monthly {
    margin: 0 10% 0 10%;
    width: 80%;
    height: 37%;
    /*opacity: 0.1;*/
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    background-color: rgba(196, 196, 196, .05);
}

.estimated {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #DB1F26;
    width: 100%;
}

.estimated-price {
    padding-bottom: 2px !important;
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 18px;
    /* identical to box height, or 150% */
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #000000;
}

.recommended-plan-p-tags {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 28px;
    /* identical to box height, or 150% */
    letter-spacing: 0.02em;
    text-transform: capitalize;

    color: #000000;

}

.leftRecommendedText {
    width: 65% !important;
    float: left !important;
    text-align: left !important;
    margin-left: 5px !important;
}

.rightRecommendedText {
    width: 25% !important;
    float: right !important;
    text-align: right !important;
    margin-right: 5px !important;
}

.MuiSlider-markLabel {
    font-size: 9.5px !important;
}

.resolution-image {
    width: 2% !important;
    padding: 0 18px !important;
}

.priceRight {
    width: 50%;
    float: right;
    text-align: right;
    border-top: 1px solid gray;
    padding-right: 5px;
    /*padding-top: 50px;*/
}
.priceLeft {
    width: 50%;
    float: left;
    text-align: left;
    border-top: 1px solid gray;
    padding-left: 5px;
    /*margin-top: 50px;*/
}


@media only screen and (min-width: 1539px) {

    .priceRight {
        width: 50%;
        float: right;
        text-align: right;
        border-top: 1px solid gray;
        padding-right: 5px;
        margin-top: 50px;
    }
    .priceLeft {
        width: 50%;
        float: left;
        text-align: left;
        border-top: 1px solid gray;
        padding-left: 5px;
        margin-top: 50px;
    }


    .resolution-image {
        width: 12% !important;
        padding: 0 5% !important;
    }

    .MuiSlider-markLabel {
        font-size: 12px !important;
    }

    .leftRecommendedText {
        width: 40% !important;
        float: left !important;
        text-align: left !important;
        margin-left: 5px !important;
    }

    .rightRecommendedText {
        width: 40% !important;
        float: right !important;
        text-align: right !important;
        margin-right: 5px !important;
    }

    .slider-text {
        font-family: 'Lato', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height, or 150% */
        letter-spacing: 0.02em;
        text-transform: capitalize;
        text-align: left;
        color: #000000;
        margin: 15px 0 0px 10px !important;
    }

    .recommended {
        font-family: 'Lato', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 24px;
        /* identical to box height, or 100% */
        letter-spacing: 0.02em;
        text-transform: capitalize;
        color: #000000;
        padding: 5% 5% 1% 5% !important;
    }

    .monthly {
        margin: 0 10% 0 10%;
        width: 80%;
        height: 37%;
        /*opacity: 0.1;*/
        border: 1px solid rgba(0, 0, 0, 0.1);
        box-sizing: border-box;
        background-color: rgba(196, 196, 196, .05);
    }

    .estimated {
        font-family: 'Lato', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height, or 150% */
        letter-spacing: 0.02em;
        text-transform: capitalize;
        color: #DB1F26;
        width: 100%;
    }

    .estimated-price {
        padding-bottom: 2px !important;
        font-family: 'Lato', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        line-height: 24px;
        /* identical to box height, or 150% */
        letter-spacing: 0.02em;
        text-transform: capitalize;
        color: #000000;
    }

    .recommended-plan-p-tags {
        font-family: 'Lato', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
        /* identical to box height, or 150% */
        letter-spacing: 0.02em;
        text-transform: capitalize;

        color: #000000;

    }
    
    .hidden-spacer-buttons {
        margin-top: 0px !important;
        margin-left: 5% !important;
        width: 90% !important;
        height: unset !important;
        background-color: transparent !important;
        border: transparent !important;
        color: transparent !important;
        font-family: 'Lato', sans-serif !important;
        font-style: normal !important;
        font-weight: bold !important;
        font-size: 16px !important;
        line-height: 40px !important;
        text-align: center !important;
        letter-spacing: 0.05em !important;
        text-transform: uppercase !important;
    }
.hidden-spacer-buttons:hover {
             background-color: rgba(219, 31, 38, .8) !important;
             border: rgba(219, 31, 38, .8) !important;
    }

    .buttonStyleInternal {
        width: 100% !important;
        height: 100% !important;
        font-size: 16px !important;
        line-height: 40px !important;
    }

    .red-button {
        margin-top: 10px !important;
        margin-left: 5% !important;
        width: 90% !important;
        font-size: 16px !important;
        line-height: 40px !important;
    }

    .gray-button {
        margin-top: 10px !important;
        margin-left: 5% !important;
        width: 90% !important;
        font-size: 16px !important;
        line-height: 40px !important;
    }

    .pricingP {
        height: 75px !important;
        font-family: 'Lato', sans-serif !important;
        font-style: normal !important;
        font-weight: 500 !important;
        font-size: 16px !important;
        line-height: 32px !important;
        letter-spacing: 0.02em !important;
        text-transform: capitalize !important;
        color: #000000 !important;
        /*border-bottom: 1px solid rgba(196, 196, 196, .2) !important;*/
    }

    .pricingPAC {
        height: 75px !important;
        font-family: 'Lato', sans-serif !important;
        font-style: normal !important;
        font-weight: 1000 !important;
        font-size: 20px !important;
        line-height: 32px !important;
        letter-spacing: 0.02em !important;
        text-transform: capitalize !important;
        color: #000000 !important;
    }

    .pricingBox {
        width: 280px !important;
        background: #FFFFFF !important;
        box-shadow: 0px 5.41176px 37.8824px rgba(0, 0, 0, 0.1) !important;
        border-radius: 10.8235px !important;
        /*// paddingTop: 10px !important;*/
        margin: 0 15px 0 15px !important;
        text-align: center !important;
    }

    .pricingTableCol {
        width: 280px !important;
        /*padding-top: -4px !important;*/
        margin: 0 15px 0 15px !important;
        text-align: center !important;
    }

    .pricingPTitle {
        font-family: 'Lato', sans-serif !important;
        font-style: normal !important;
        font-weight: 1000 !important;
        font-size: 20px !important;
        line-height: 32px !important;
        letter-spacing: 0.02em !important;
        text-transform: capitalize !important;
        color: #000000 !important;
    }
}

@media only screen and (min-width: 2062px) {
    .recommended-plan-p-tags {
        font-size: 14px;
        line-height: 20px;

    }

    .estimated-price {
        font-size: 16px;
        line-height: 24px;
    }
}

.pricingPTitle {
    font-family: 'Lato', sans-serif !important;
    font-style: normal !important;
    font-weight: 1000 !important;
    font-size: 14px !important;
    line-height: 22px !important;
    letter-spacing: 0.02em !important;
    text-transform: capitalize !important;
    color: #000000 !important;
}

tr {
    height: 50px !important;
}

.h3Style-no-align {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    /* identical to box height, or 100% */
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #000000;
}

.h5-use-case {
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */
    text-align: center;
    letter-spacing: 0.02em;
    color: #000000;
}

.sign-up {
    margin: 10% 25% 0% auto;
    width: 650px;
    height: 699px;
    background: #FFFFFF;
    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.05);
    text-align: center;
}

.slider-section {
    width: 100vw;
    height: 843px;
    /*border: 1px solid black;*/
    text-align: center;
    background: #FAFAFA;
    /*opacity: 0;*/
}

.MuiSlider-root {
    width: 90% !important;
    height: 8px !important;
}

.MuiSlider-rail {
    color: gray;
    height: 8px !important;
}

.MuiSlider-track, .MuiSlider-Active {
    color: #DB1F26;
    height: 8px !important;
}

.MuiSlider-thumb {
    color: #DB1F26;
    height: 15px !important;
    width: 15px !important;
    box-shadow: 0px 0px 0px 5px rgba(219, 31, 38, 0.16) !important;
}

.MuiSlider-mark, .MuiSlider-markActive {
    color: transparent;
    height: 12px !important;
    width: 12px !important;
    border: 1px solid rgba(255, 255, 255, .2) !important;
    border-radius: 50% !important;
    opacity: .0 !important;
}

.MuiSlider-thumb.MuiSlider-active {
    box-shadow: 0 0 0 14px rgba(219, 31, 38, 0.16) !important;
}

.MuiLinearProgress-root {
    background-color: white !important;
    width: 50vw !important;
    height: 88px !important;
    border: 1px solid black;
}

.MuiTypography-body1 {
  font-size: 0.875rem!important;
}

.MuiLinearProgress-bar {
    background-color: #A1A1A1 !important;
}

.MuiLinearProgress-bar1Determinate {
    transition: transform 1.5s linear;
}

.progressH4 {
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    width: 400px;
    font-size: 22px;
    line-height: 25px;
    text-align: left;
    margin: 5% 0;
}

.deploy-text {
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */
    text-align: center;
    color: #000000;
    opacity: 0.7;
    /*margin: 10% 25%;*/
}

.MuiGrid-grid-xs-2 {
    flex-grow: 1 !important;
    max-width: 10% !important;
    flex-basis: 10% !important;
}

.sub-text {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */
    letter-spacing: 0.02em;
    text-transform: capitalize;
    margin-top: 55px;
    height: 30px;
    color: #000000;
}

.h3StyleDash {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: bolder;
    font-size: 30px;
    line-height: 48px;
    letter-spacing: 0.02em;
    color: #000000;
    text-align: left;
    margin: 5% 0 -5% 5%;
}


.viewBox {
    background-image: url(/public/XDNBackground.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw !important;
    height: calc(100vh - 128px);
    height: -webkit-calc(100vh - 128px);
}

.dashboardLeft {
    /*border: 1px solid black;*/
    /*height: 75vh;*/
    /*width: 40%;*/
    /*background-color: white;*/
    /*    margin: 5% 0 0 5% !important; */
    margin-left: 5% !important;
    margin-top: 0px !important;
    /*border: 1px solid black;*/
    /*text-align: center;*/
    /*padding: 0 0 5% 0;*/
}

.dashboardRight {
    /*border: 1px solid black;*/
    /*height: 75vh;*/
    /*width: 40%;*/
    /*background-color: white;*/
    /*margin: 5% 0 0 5% !important;*/
    margin-left: 5% !important;
    margin-top: 0px !important;
    /*border: 1px solid black;*/
    /*text-align: center;*/
    /*padding: 0 0 5% 0;*/
    display: flex;
    flex-direction: column;
}

.statsBox {
    border: 1px solid black;
    background-color: #fff;
}

.statsBox p {
    font-weight: bold;
    margin: 0px 0 0px 20px !important;
    font-family: 'Lato', sans-serif !important;
    font-style: normal !important;
    font-size: 12px !important;
    line-height: 40px !important;
    /*text-align: center !important;*/
    letter-spacing: 0.05em !important;
    text-transform: uppercase !important;
}

.regionBox {
    height: 40vh;
}

.regionBox h5 {
    width: 40% !important;
    margin: 30px 0 10px 0px !important;
    font-family: 'Lato', sans-serif !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 12px !important;
    /*text-align: center !important;*/
    letter-spacing: 0.05em !important;
    text-transform: uppercase !important;
}

.regionBox span {
    float: right;
    width: 40%;
}

.dashButton {
    width: 100% !important;
    height: 50px !important;
    background-color: #A1A1A1 !important;
    border: #A1A1A1 !important;
    font-family: 'Lato', sans-serif !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 12px !important;
    line-height: 40px !important;
    /*text-align: center !important;*/
    letter-spacing: 0.05em !important;
    text-transform: uppercase !important;
    color: #FFFFFF !important;
    margin: 10px 0 10px 0 !important;
    z-index: 1000 !important;
}

.statsText {
    margin: 30px 0 10px 0px !important;
    font-family: 'Lato', sans-serif !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 12px !important;
    /*text-align: center !important;*/
    letter-spacing: 0.05em !important;
    text-transform: uppercase !important;
}

.settingsText {
    margin: 30px 0 10px 0px !important;
    font-family: 'Lato', sans-serif !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 12px !important;
    /*text-align: center !important;*/
    letter-spacing: 0.05em !important;
    text-transform: uppercase !important;
    cursor: pointer;
}

.streamsBox {
    max-height: 69vh;
    min-height: 48px;
    border: 1px solid black;
}

.MuiGrid-root .MuiGrid-item .orderSummary {
    margin-left: 10% !important;
}

.MuiButton-text {
    padding: 0 !important;
}

.hoverIcon {
    min-width: unset !important;
    width: 25px;
    height: 25px;
    margin: 0 auto 5px 5px !important;
}

.refreshNodeButton {
    background-color: #A1A1A1 !important;
    border: #A1A1A1 !important;
    font-family: 'Lato', sans-serif !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 12px !important;
    /*text-align: center !important;*/
    letter-spacing: 0.05em !important;
    text-transform: uppercase !important;
    color: #FFFFFF !important;
    margin: 10px 0 10px 0 !important;
    padding: 5px 10px !important;
}

.nodeGroupRemoval {
  background-color: #ffadad;
  padding: 10px;
}

.spaces {
    white-space: pre-line;
}
.navProg {
    margin-top: 64px;
    height: 64px;
    width: 100vw;
    color: black;
    background-color: #E2E2E2;
}

.progLink {
    font-family: "Work Sans", sans-serif;
    height: 64px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #A0A0A0;
    mix-blend-mode: normal;
    margin: 16px 0 auto 0;
}

.navDark {
    height: 64px;
    width: 75%;
    background-color: #171717;
}

.arrowBox {
    width: 0;
    height: 64px;
    border-top: 32px solid transparent;
    border-bottom: 32px solid transparent;
    border-left: 32px solid #171717;
}

.progLinkActive {
    color: #DB1F26;
    font-family: "Work Sans", sans-serif;
    height: 64px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    mix-blend-mode: normal;
    margin: 16px 0 auto 0;
}

.dot {
    transform: translateY(20%);
    margin-right: 5px;
    height: 14px;
    width: 14px;
    background-color: #DB1F26;
    border-radius: 50%;
    display: inline-block;
}

.correctionClass {
    margin-top: -17px !important;
}
.MuiInput-underline:after {
    border-bottom: 2px solid #DB1F26 !important;
}

.MuiButton-containedPrimary {
    color: #fff;
    background-color: #DB1F26 !important;
}

input:focus{
    background-color: rgba(219, 31, 38, .2) !important;
    background-image: none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 32px white inset;/*your box-shadow*/
    -webkit-text-fill-color: #333;
}

.MuiFormHelperText-root.Mui-error {
    color: transparent !important;
}
