.navProg {
    margin-top: 64px;
    height: 64px;
    width: 100vw;
    color: black;
    background-color: #E2E2E2;
}

.progLink {
    font-family: "Work Sans", sans-serif;
    height: 64px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #A0A0A0;
    mix-blend-mode: normal;
    margin: 16px 0 auto 0;
}

.navDark {
    height: 64px;
    width: 75%;
    background-color: #171717;
}

.arrowBox {
    width: 0;
    height: 64px;
    border-top: 32px solid transparent;
    border-bottom: 32px solid transparent;
    border-left: 32px solid #171717;
}

.progLinkActive {
    color: #DB1F26;
    font-family: "Work Sans", sans-serif;
    height: 64px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    mix-blend-mode: normal;
    margin: 16px 0 auto 0;
}

.dot {
    transform: translateY(20%);
    margin-right: 5px;
    height: 14px;
    width: 14px;
    background-color: #DB1F26;
    border-radius: 50%;
    display: inline-block;
}

.correctionClass {
    margin-top: -17px !important;
}