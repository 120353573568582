@import url('http://fonts.googleapis.com/css?family=Lato&subset=latin,latin-ext');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans');

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiSnackbar-root {
    background-color: #DB1F26;
    margin-top: 128px !important;
}

@media only screen and (max-width: 959px) {
    .mobile-warning {
        background: linear-gradient(91.81deg, #E0E0E0 1.29%, #F5F3F3 128.06%), #F3F2F2;
        opacity: 0.8;
        height: 100vh;
        width: 100vw;
        z-index: 1000;
    }

    .hide-on-mobile {
        display: none !important;
    }

}

@media only screen and (min-width: 960px) {
    .mobile-warning {
        z-index: -10000;
        display: none;
    }

    .hide-on-mobile {
        display: unset !important;
    }
}

.mobile-text {
    -webkit-animation: color-change 5s infinite;
    -moz-animation: color-change 5s infinite;
    -o-animation: color-change 5s infinite;
    -ms-animation: color-change 5s infinite;
    animation: color-change 5s infinite;
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: bolder;
    font-size: 40px;
    line-height: 48px;
    letter-spacing: 0.02em;
    text-align: center;
    margin: 25% 10%;
}

@-webkit-keyframes color-change {
    0%, 100% {
        color: #000000;
    }
    50% {
        color: #DB1F26;
    }
}

@-moz-keyframes color-change {
    0%, 100% {
        color: #000000;
    }
    50% {
        color: #DB1F26;
    }
}

@-ms-keyframes color-change {
    0%, 100% {
        color: #000000;
    }
    50% {
        color: #DB1F26;
    }
}

@-o-keyframes color-change {
    0%, 100% {
        color: #000000;
    }
    50% {
        color: #DB1F26;
    }
}

@keyframes color-change {
    0%, 100% {
        color: #000000;
    }
    50% {
        color: #DB1F26;
    }
}

.MuiGrid-root .MuiGrid-item .orderSummary {
    margin-left: 10% !important;
}

.loading-icon {
    width: 50px;
    height: 50px;
    color: #fff;
    position: absolute;
    left: 44%;
    top: 34%
}

.MuiFormLabel-root.Mui-focused {
    color: #DB1F26 !important;
}

.makeStyles-errors-27 {
    margin-top: 16px;
    border-left: 8px solid #DB1F26 !important;
    color: #000000 !important;
}

.MuiSwitch-colorPrimary.Mui-checked {
    color: #DB1F26 !important;
}
.MuiSwitch-track {
    background-color: #DB1F26 !important;
}